import { CommonTranslation, useCommonTranslations } from "../components/CommonTranslations";
import Layout from "../components/layout";
import Seo from "../components/seo";
import BookImage from "../images/book.jpg";
import SergeyImage from "../images/sergey-colored.jpg";
import React from "react";
import { ButtonLink } from "../components/Button";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { useFilteredByLocale, useLocationInfo } from "../hooks";
import { node } from "prop-types";

export default function Cast({ location }: { location: Location }) {
  const commonTranslations = useCommonTranslations();

  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title={commonTranslations.theBook} />
      <Body location={location} />
    </Layout>
  );
}

const Body = ({ location }: { location: Location }) => {
  const { title, firebirdFilmmakers, movedBySergeyStory, muchLove, peeterAndTom, textBlocks } = useTheBook();

  return (
    <div className="w-screen min-h-screen pb-16">
      <div className="relative">
        <img src={BookImage} className="object-cover w-full h-screen md:object-bottom" />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-50">
          <div>
            <h1 className="mb-4 text-4xl font-bold text-center md:text-6xl">{title}</h1>
            <PreorderBook className="py-3 text-xl text-center" />
          </div>
        </div>
      </div>
      <div className="grid items-center min-h-screen grid-cols-12 px-2 py-16 gap-y-12 md:gap-y-0 md:gap-x-12 md:px-12">
        <div className="col-span-10 col-start-2 md:col-start-1 md:col-span-4">
          <img src={SergeyImage} className="w-full" />
        </div>
        <div className="col-span-10 col-start-2 text-white md:col-start-5 md:col-span-8">
          {textBlocks.map(({ text }, index) => (
            <p key={index} className="mb-2">
              {text}
            </p>
          ))}
          <p className="mb-4 font-bold">{movedBySergeyStory}</p>
          <div className="w-full mb-2 font-bold">
            <p className="mb-2 text-center">{muchLove}</p>
            <p className="text-center">{peeterAndTom}</p>
            <p className="mb-4 text-center">{firebirdFilmmakers}</p>
            <div className="flex justify-center">
              <PreorderBook />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PreorderBook = ({ className }: { className?: string }) => {
  const { preorderNow, bookLink } = useTheBook();

  return (
    <ButtonLink
      className={cx("py-2 bg-primaryDark hover:bg-opacity-80", className)}
      border={false}
      to={bookLink}
      target="_blank"
    >
      {preorderNow}
    </ButtonLink>
  );
};

export const useTheBook = () => {
  const {
    allStrapiTheBook: { edges },
  } = useStaticQuery<{ allStrapiTheBook: EdgesQuery<{ node: TheBook }> }>(query);
  const locationInfo = useLocationInfo();

  const data = edges.map(({ node }) => {
    const bookLinks = (node.bookLinks as unknown as string).split("\n").reduce((acc, linkString) => {
      const [country, link] = linkString.split(" - ");

      return { ...acc, [country]: link };
    }, {} as { [key: string]: string });

    const bookLink = locationInfo && locationInfo.country ? bookLinks[locationInfo.country] : undefined;

    return { ...node, bookLinks, bookLink: bookLink ?? node.preorderLink };
  });

  return useFilteredByLocale(data)[0] as TheBook;
};

const query = graphql`
  query TheBookQuery {
    allStrapiTheBook {
      edges {
        node {
          title
          locale
          preorderNow
          preorderLink
          textBlocks {
            text
          }
          movedBySergeyStory
          muchLove
          peeterAndTom
          firebirdFilmmakers
          bookLinks
        }
      }
    }
  }
`;
